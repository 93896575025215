var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "row push"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "disabled": _vm.disabled,
      "error": _vm.error['name'],
      "label": "Name",
      "type": "text"
    },
    model: {
      value: _vm.value.name,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "name", $$v);
      },
      expression: "value.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "email",
      "disabled": _vm.disabled,
      "error": _vm.error['email'],
      "label": "Email",
      "type": "text"
    },
    model: {
      value: _vm.value.email,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "email", $$v);
      },
      expression: "value.email"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "roleId",
      "disabled": _vm.disabled,
      "error": _vm.error['roleId'],
      "label": "Role",
      "type": "select",
      "options": _vm.roleOptions
    },
    model: {
      value: _vm.value.roleId,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "roleId", $$v);
      },
      expression: "value.roleId"
    }
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v(" User Details")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("An email will be sent to the user to finalise their account creation.")])]);
}]

export { render, staticRenderFns }